<template>
	<div>
	
		<div class="font-bold text-xl mb-10">Add a single staff</div>

		<form @submit.prevent="submit" key="company">

			<template v-if="getFormError(form)">
				<div class="alert alert-red-soft mb-10">
					<span class="alert-icon">!</span>
					<span>{{ getFormError(form) }}</span>
				</div>
			</template>
			
			<div class="grid grid-cols-2 gap-4">
				<div class="col-span-1">
					<form-group type="first_name"
						leftIcon="person-outline"
						name="first_name"
						:loading="groups.loading"
						:form="form"
						v-model="form.data.first_name.value"
					>
						First Name
					</form-group>
				</div>
				<div class="col-span-1">
					<form-group type="last_name"
						leftIcon="person-outline"
						name="last_name"
						:loading="groups.loading"
						:form="form"
						v-model="form.data.last_name.value"
					>
						Last Name
					</form-group>
				</div>
			</div>

			<form-group type="email"
				leftIcon="mail-outline"
				name="email"
				:loading="groups.loading"
				:form="form"
				v-model="form.data.email.value"
			>
				Email Address
			</form-group>

			<form-group
				type="phone"
				leftIcon="call-outline"
				name="phone"
				:loading="groups.loading"
				:form="form"
				v-model="form.data.phone.value"
			>
				Phone Number
			</form-group>

			<form-group
				type="select"
				:options="groupOptions"
				leftIcon="people-circle-outline"
				name="group"
				:loading="groups.loading"
				:form="form"
				v-model="form.data.group.value"
			>
				Department
			</form-group>

			<div class="-mt-6 mb-6">
				<router-link :to="{ name: 'groups-new' }" class="text-sm text-gray-700">+ Create a new department</router-link>
			</div>

			<div class="flex flex-row items-center mb-6">
				<checkbox id="is_admin" class="mr-3" v-model="form.data.is_admin.value" />
				<label for="is_admin" class="text-xs">Assign Admin Role</label>
			</div>

			<template v-if="wantsAdmin">
				<form-group
					type="select"
					:options="roles"
					left-icon="person-outline"
					name="role"
					:loading="groups.loading"
					v-model="form.data.role.value"
					:form="form"
				>
					Role
				</form-group>
			</template>

			<div class="text-right">
				<button type="submit" class="btn btn-blue" :disabled="form.loading">
					<span v-if="form.loading">Adding...</span>
					<span v-else>Add Employee</span>
				</button>
			</div>

		</form>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				All employees were successfully added.
			</div>

			<router-link :to="{name: 'staff'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				View all Employees
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Register a new employee.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Here you can register a company employee giving them access to the platform.</p>
					<p class="mb-2">
						You can choose to 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-employee'">register one at a time</a> 
						or 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-multiple'">upload a bunch at once</a>.
					</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: '',
				form: this.$options.basicForm([
					{name: 'first_name', rules: 'required'},
					{name: 'last_name', rules: 'required'},
					{name: 'email', rules: 'required|email'},
					{name: 'phone', rules: 'required|phone'},
					{name: 'group', rules: 'required'},
					{name: 'role', rules: 'required', value: 'company_staff'},
					{name: 'is_admin', rules: 'nullable', value: false},
					// {name: 'account_no', rules: 'required|number|length:10'},
					// {name: 'bank_name', rules: 'required'},
				]),
				roles: [
					{title: 'Super Admin', value: 'company_super'},
					{title: 'HR', value: 'company_hr'},
					{title: 'Finance Manager', value: 'company_finance'},
					{title: 'Audit', value: 'company_audit'},
					{title: 'Department Head', value: 'company_department_head'},
					{title: 'Unit Head', value: 'company_unit_head'},
					// {title: 'Employee', value: 'company_staff'},
				],
			}
		},
		computed: {
			groups() {
				return this.resources.groups;
			},
			groupOptions() {
				return this.groups.data.map( group => ({
					value: group.id,
					title: group.name
				}) );
			},
			wantsAdmin() {
				return this.form.data.is_admin.value;
			}
		},
		beforeMount() {
			this.getGroups();
		},
		watch: {
			'form.data.is_admin.value'(is_admin) {
				if (is_admin) {
					this.form.data.role.value = '';
				}else {
					this.form.data.role.value = 'company_staff';
				}
			}
		},
		methods: {
			async getGroups(forced = false) {
				this.loadResource('groups', forced);
			},
			async getStaff(forced = false) {
				this.loadResource('staff', forced);
			},
			async submit() {
				if (!this.validateForm(this.form)) {
					return false;
				}

				this.form.error = false;
				this.form.loading = true;

				await this.$post({
					url: `${this.$baseurl}/companies/staff/add`,
					data: {
						...this.getFormData(this.form),
						// account_no: this.form.data.account_no.value,
						// bank_name: this.form.data.bank_name.value,
						companyid: this.user.company_id
					},
					headers: this.headers,
					success: () => {
						this.$refs.successModal.open();
						this.form = this.resetForm(this.form);
						this.getStaff(true);
					},
					error: error => {
						console.log(error);
						this.form.error = error;

						this.mapFormErrors(this.form, error?.response?.data?.errors);
					}
				});

				this.form.loading = false;
			},
		}
	}
</script>