<template>
	<div>

		<div class="font-bold text-xl mb-10">Add multiple</div>

		<template v-if="!preview">

			<div key="admin">
				
				<template v-if="getFormError(form)">
					<div class="alert alert-red-soft mb-10">
						<span class="alert-icon">!</span>
						<span>{{ getFormError(form) }}</span>
					</div>
				</template>

				<div class="flex flex-row justify-between">
					<div class="text-sm mb-4">Upload Excel or CSV File </div>
					<a href="#!" class="underline text-xs mb-4" @click.prevent="help">Need help?</a>
				</div>

				<dropzone accept=".xlsv, .xlsx, .csv" v-model="form.data.sheet.value" :uploading="uploading" :uploaded="uploaded"></dropzone>

				<template v-if="bulkFieldErrors">
					<div class="text-red-500 text-sm mb-5">Please fix the following errors and try again.</div>

					<div class="border border-blue-200 rounded text-xs mb-10 break-all">

						<template v-for="(row, n) in bulkFieldErrors">
							<div :key="n">
								<div :class="{'border-t border-blue-200': n != 0}">
									<div class="border-b border-blue-200 px-3 py-2">
										Row: {{ row.index }}
									</div>
									<div class="grid grid-cols-3">
										<div class="col-span-1 px-3 py-2">
											<div class="mb-2">{{ row.data.name }}</div>
											<div v-if="row.errors.name" class="text-red-500">{{ row.errors.name[0] }}</div>
										</div>
										<div class="col-span-1 px-3 py-2 border-l border-blue-200">
											<div class="mb-2">{{ row.data.email }}</div>
											<div v-if="row.errors.email" class="text-red-500">{{ row.errors.email[0] }}</div>
											
										</div>
										<div class="col-span-1 px-3 py-2 border-l border-blue-200">
											<div class="mb-2">{{ row.data.phone }}</div>
											<div v-if="row.errors.phone" class="text-red-500">{{ row.errors.phone[0] }}</div>
											
										</div>
									</div>
								</div>
							</div>
						</template>

					</div>

				</template>

				<div class="text-right">
					<button
						type="button"
						class="btn btn-blue"
						:disabled="!sheetRows || !sheetRows.length"
						@click.prevent="showPreview"
					>
						Preview
					</button>
				</div>

			</div>

		</template>

		<template v-else>
			
			<div class="-mb-7">
				<button type="button" class="text-blue-500 text-sm focus:outline-none" @click.prevent="back">
					<span class="caret caret-left inline-block"></span> Back
				</button>
			</div>

			<div class="flex">
				<div class="md:w-2/3 xl:w-1/2 mx-auto">
					<h1 class="text-2xl font-bold mb-5">Adding Multiple Employees</h1>

					<div class="text-sm xl:w-7/10 mb-10">
						Have a glance though the uploaded data for a quick review before proceeding.
					</div>

					<div class="border border-blue-200 mb-5">
						<table class="table table-fixed w-full">
							<thead>
								<tr>
									<th class="w-1/2">Staff Name</th>
									<th class="w-1/2">Email Address</th>
								</tr>
							</thead>
						</table>
						<div class="max-h-500px overflow-y-auto">
							<table class="table table-fixed w-full">
								<tbody>
									<template v-for="(row, n) in sheetRows">
										<tr :key="n">
											<td class="w-1/2">
												<template v-if="row.name">
													{{ row.name }}
												</template>
												<template v-else>
													<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
														<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
															!
														</div>
														<span>No name provided</span>
													</div>
												</template>
											</td>
											<td class="w-1/2">
												<template v-if="row.email">
													{{ row.email }}
												</template>
												<template v-else>
													<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
														<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
															!
														</div>
														<span>No email provided</span>
													</div>
												</template>
											</td>
											<td class="w-1/2">
												<template v-if="row.phone">
													{{ row.phone }}
												</template>
												<template v-else>
													<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
														<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
															!
														</div>
														<span>No phone number provided</span>
													</div>
												</template>
											</td>
										</tr>
									</template>
								</tbody>
							</table>
						</div>
					</div>

					<div class="text-right">
						<button type="button" class="text-blue-500 text-sm focus:outline-none mr-16" @click.prevent="back">
							<span class="caret caret-left inline-block"></span> Back
						</button>
						<button class="button bg-blue-500 hover:bg-blue-600 text-white" @click.prevent="submit">Submit</button>
					</div>

				</div>
			</div>

		</template>

		<modal className="w-full md:w-1/2 lg:w-8/10 xl:w-5/10" ref="confirmationModal">

			<template v-if="sheetRows">
				<div class="text-lg font-bold mb-4">
					Review staff to upload.
				</div>

				<div class="mb-10">
					<table class="table table-fixed w-full">
						<thead>
							<tr>
								<th class="w-1/5">Name</th>
								<th class="w-1/5">Email</th>
								<th class="w-1/5">Phone</th>
								<th class="w-1/5">Role</th>
								<th class="w-1/5">Department</th>
							</tr>
						</thead>
					</table>
					<div class="max-h-500px overflow-y-auto">
						<table class="table table-fixed w-full">
							<tbody>
								<template v-for="(row, n) in sheetRows">
									<tr :key="n">
										<td class="w-1/5 text-overflow-ellipse">
											<template v-if="row.name">
												{{ row.name }}
											</template>
											<template v-else>
												<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
													<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
														!
													</div>
													<span>No name provided</span>
												</div>
											</template>
										</td>
										<td class="w-1/5 text-overflow-ellipse">
											<template v-if="row.email">
												{{ row.email }}
											</template>
											<template v-else>
												<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
													<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
														!
													</div>
													<span>No email provided</span>
												</div>
											</template>
										</td>
										<td class="w-1/5 text-overflow-ellipse">
											<template v-if="row.phone">
												{{ row.phone }}
											</template>
											<template v-else>
												<div class="flex flex-row items-center text-red-500 py-2 px-3 rounded-full bg-red-100 text-xs">
													<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-2">
														!
													</div>
													<span>No phone number provided</span>
												</div>
											</template>
										</td>
										<td class="w-1/5">
											<custom-select
												:options="roles"
												class="select px-4 py-3 border border-blue-200 rounded relative"
												v-model="row.role"
											/>
										</td>
										<td class="w-1/5">
											<custom-select
												:options="groupOptions"
												class="select px-4 py-3 border border-blue-200 rounded relative"
												v-model="row.group"
											/>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</div>
				<div class="text-right">
					<button
						class="btn btn-red mr-4"
						type="button"
						:disabled="form.loading"
					>
						Cancel
					</button>

					<button
						class="btn btn-blue"
						type="button"
						@click.prevent="submit"
						:disabled="form.loading"
					>
						<span v-if="form.loading">Uploading</span>
						<span v-else>Upload Data</span>
					</button>
				</div>
			</template>
			<template v-else>
				<div class="text-sm text-gray-700">No Data To Preview</div>
			</template>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				All employees were successfully added.
			</div>

			<router-link :to="{name: 'staff'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				View all Employees
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="AllSuccessModal">

			<img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Success!
			</div>
			<div class="text-xs mb-10">
				All employees were successfully added.
			</div>

			<router-link :to="{name: 'staff'}" class="button bg-blue-500 hover:bg-blue-600 text-white">
				View all Employees
			</router-link>
			
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 px-10 py-10" ref="tipModal">
			<div class="text-lg font-bold mb-4">
				The file format you need
			</div>

			<div class="mb-4 text-sm text-gray-500">Shown below is the compatible format for adding bulk employees.</div>

			<img :src="sheetPreview" alt="Preview" class="w-full mb-8">

			<div class="flex flex-row">
				<div class="w-5px h-5px bg-blue-500 mr-2 mt-5px" style="border-radius: 50%; flex: 0 0 5px"></div>
				<div class="text-xs text-gray-500 font-hairline">
					When done, you would have to save the file as an Excel File (.xls / .xlsx) or as a CSV File (.csv).
				</div>
			</div>
		</modal>

		<modal className="w-full md:w-2/5 xl:w-3/10 text-center" ref="helpModal" @close="closeModal(modalKey, $refs.helpModal)">

			<div class="mb-10">
				<div class="text-xl font-bold mb-10">
					Register a new employee.
				</div>

				<div class="font-light text-gray-500 text-sm">
					<p class="mb-2">Here you can register a company employee giving them access to the platform.</p>
					<p class="mb-2">
						You can choose to 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-employee'">register one at a time</a> 
						or 
						<a href="#!" class="text-black font-normal" @click.prevent="tab = 'add-multiple'">upload a bunch at once</a>.
					</p>
				</div>
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-white"
				@click.prevent="$refs.helpModal.close"
			>
				Got it.
			</button>

		</modal>
	</div>
</template>

<script>
	import XLSX from 'xlsx';

	export default {
		data() {
			return {
				form: this.$options.basicForm([{name: 'sheet', value: null}]),
				name: '',
				preview: false,
				roles: [
					{title: 'Super Admin', value: 'company_super'},
					{title: 'HR', value: 'company_hr'},
					{title: 'Finance Manager', value: 'company_finance'},
					{title: 'Audit', value: 'company_audit'},
					{title: 'Department Head', value: 'company_department_head'},
					{title: 'Unit Head', value: 'company_unit_head'},
					{title: 'Employee', value: 'company_staff'},
				],
				sheetPreview: require('@/assets/sheet-preview-2.png'),
				sheetRows: null,
				uploading: false,
				uploaded: false,
			}
		},
		computed: {
			groups() {
				return this.resources.groups;
			},

      bulkFieldErrors() {
        const errors = this.form.error?.response?.data?.errors;
        var bulkFieldErrors = null;

        if (errors && Object.keys(errors).length) {
          bulkFieldErrors = {};

          for (let key in errors) {
            let match = key.match(/staff.(\d+).(\w+)/);
            console.log(match)
            let index = match?.[1];
            let name = match?.[2];
            let error = errors[key];

            // bulkFieldErrors.push({ data: this.sheetRows[index], column: name, error  });
            if (bulkFieldErrors[index]) {
              bulkFieldErrors[index].errors[name] = error;
            }else {
              bulkFieldErrors[index] = {
                index,
                data: this.sheetRows[index],
                errors: {
                  [name]: error
                }
              }
            }

          }
        }

        return bulkFieldErrors;
      },
			fileExtension() {
				const sheet = this.form.data.sheet.value;
				if (!sheet) {
					return null;
				}

				const extensions = {
					csv: [/.csv$/i],
					excel: [/.xls$/i]
				};

				for (let extension in extensions) {
					if (extensions[extension].find( expression => sheet.name.match(expression) )) {
						return extension;
					}
				}

				return null;
			},
			groupOptions() {
				return this.groups.data.map( group => ({
					value: group.id,
					title: group.name
				}) );
			},
		},
		beforeMount() {
			this.getGroups(false);
		},
		watch: {
			'form.data.sheet.value'(data) {
				if (!data) {
					this.sheetRows = null;
					return null;
				}

				const Reader = new FileReader();

				Reader.onload = (event) => {
					
					const workBook = XLSX.read(event.target.result, { type: 'binary' });

					const sheet = workBook.Sheets[workBook.SheetNames[0]];
					const rows = XLSX.utils.sheet_to_json(sheet);

					this.sheetRows = rows.map( row => {
						const data = {};

						data.name = row[Object.keys(row)[0]];
						data.email = row[Object.keys(row)[1]];
						data.phone = row[Object.keys(row)[2]];
						data.role = 'company_staff';
						data.group = null;
						
						// for (let column in row) {
						// 	let columnName = column.toLowerCase().replace(/\s/, '_')
						// 	data[columnName] = row[column];
						// }
						return data;
					} )

				}

				Reader.readAsBinaryString(data);

			},
			'employeeForm.data.is_admin.value'(is_admin) {
				if (is_admin) {
					this.employeeForm.data.role.value = '';
				}else {
					this.employeeForm.data.role.value = 'company_staff';
				}
			}
		},
		methods: {
			back() {
				this.preview = false;
			},
			success() {
				this.$refs.AllSuccessModal.open();
			},
			help() {
				this.$refs.tipModal.open();
			},
			async getGroups(forced = false) {
				this.loadResource('groups', forced);
			},
			async getStaff(forced = false) {
				this.loadResource('staff', forced);
			},
			showPreview() {
				// this.preview = true;
				this.$refs.confirmationModal.open();
			},
			async submit() {
				this.preview = false;

				// const data = new FormData();

				// data.append('file', this.form.data.sheet.value);

				this.form.loading = true;
				this.uploading = true;

				await this.$post({
					url: `${this.$baseurl}/companies/staff/addBulk`,
					data: {
						staff: this.sheetRows
					},
					headers: this.headers,
					success: () => {
						this.uploaded = true;
						this.getStaff(true);
					},
					error: (error) => {
						this.form.error = error;
					},
					axiosProps: {
						onUploadProgress: (event) => {
							console.log(event);
						},
						onDownloadProgress: (event) => {
							console.log(event);
						},
					}
				});

				this.form.loading = false;
				this.uploading = false;
			}
		}
	}
</script>